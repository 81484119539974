.container-signup {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px;
}
.signup {
  background-color: #fff;
  width: 100%;
  max-width: 450px;
  padding: 30px;
  border-radius: 3px;
  box-shadow: 0 0 20px rgba(0,0,0, .08);
  h3 {
    color: #3f395f;
    font-size: 34px;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }

  form {
    display: flex;
    flex-direction: column;
  }
  .text-input {
    background-color: #e6e6e6;
    border-radius: 5px;
    border: solid 1px #7879f5;
    color: #555;
    font-size: 20px;
    padding: 6px 20px;
    margin-bottom: 12px;
    &:focus {
      outline: none;
      border:  1px dashed #9a99ab;
    }
    &::placeholder {
      color: #555;
      font-size: 20px;
    }
    &:disabled {
      color: #999;
      border: none;
      cursor: not-allowed;
    }
  }
  .logo-upload {
    @extend .text-input;
    border:  2px dashed #7879f5;
    color: #555;
    text-align: center;
    input {
      display: none;
    }
  }
  button {
    background-color: #7879f5;
    border-radius: 3px;
    border: none;
    color: #fff;
    font-size: 20px;
    padding: 10px 0px;
    transition: all .6s;
    &:hover {
      background-color: darken(#7879f5, 5);
    }

    &.btn--update {
      background-color: #78f582;
      &:hover {
        background-color: darken(#78f582, 5);
      }
    }
  }

  .custom-select {
    background-color: #e6e6e6;
    border-color: #7879f5;
    color: #555;
    height: 44px;
    padding: 6px 20px;
    font-size: 20px;
  }
}