.preview-custom-field {
  position: absolute;
  top: 0;
  left: 0;
  word-wrap: break-word;

  &.field-image {
    background: linear-gradient(45deg, #0041d2, #ff0000);
  }
}

.flag-editing {
  line-height: 1;
  background-color: #00adb5;
  color: #fff;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  border-radius: 2px;
}