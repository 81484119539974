.table-actions {
  display: flex;
  justify-content: space-around;
  img {
    width: 25px;
    height: 25px;

    @media(max-width: 767px) {
      width: 30px;
      height: 30px;
      margin: 0 10px;
    }
  }
}

.responsive-table {
  @media(max-width: 767px) {
    overflow-x: scroll;
  }
}