.template {
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, .5);
  max-width: 300px;
  margin: 0 auto;
  &-image {
    width: 100%;
    height: 140px;
  }
  &-body {
    padding: 15px 15px 10px 15px;
    position: relative;
  }
  &-type {
    position: absolute;
    right: 0px;
    top: 0px;
    line-height: 1;
    background-color: #00adb5;
    color: #fff;
    padding: 3px 6px;
    font-size: 14px;
    font-weight: 600;
  }
  &-name {
    font-size: 22px;
    margin-bottom: 15px;
  }
  &-generate {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;

    a {
      color: #222831;
      border: 1px solid #222831;
      border-radius: 2px;
      padding: 5px 10px;
      text-decoration: none;
      transition: all .5s;
      &:hover {
        background-color: #222831;
        color: #fff;

      }
      &:first-child {
        margin-bottom: 5px;
      }
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;

    img {
      width: 22px;
      height: 22px;
    }
  }
}

.generate-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.3);
  width: 100%;
  height: 100%;
  .generate-content {
    position: relative;
  }
  .generate-fields {
    position: absolute;
  }
}