.download-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 30px 0;

  .download-content {
    position: relative;
  }
  .download-fields {
    position: absolute;
  }
}